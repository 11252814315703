import React from 'react';
import { BsTwitter, BsLinkedin } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://twitter.com/akeel_qurashi"
          rel="noreferrer"
          target="_blank"
        >
          <BsTwitter />
        </a>
      </div>
      <div>
        <a
          href="https://www.facebook.com/people/Qurashi-Akeel/100081110917655/"
          rel="noreferrer"
          target="_blank"
        >
          <FaFacebookF />
        </a>
      </div>
      <div>
          <BsLinkedin />
      </div>
    </div>
  );
};

export default SocialMedia;
